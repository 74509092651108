var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"twoPage"},[_c('swipeTop',{attrs:{"ZHtitle":_vm.ZHtitle,"ENtitle":_vm.ENtitle,"top_img":_vm.top_img}}),_c('seachVue'),(_vm.menuId != 10)?_c('classify-list',{attrs:{"toptype":_vm.toptype}}):_vm._e(),(_vm.menuId != 10)?_c('div',{staticClass:"categoryTitle"},[_c('van-icon',{staticStyle:{"transform":"rotate(180deg)"},attrs:{"name":"play"}}),_vm._v(" 业务分类 "),_c('van-icon',{attrs:{"name":"play"}})],1):_vm._e(),_c('div',{staticClass:"content"},[(_vm.menuId != 10)?_c('div',{staticClass:"itemBox"},_vm._l((_vm.subMenuList),function(item,index){return _c('div',{key:index,staticClass:"item",on:{"click":function($event){return _vm.toThree(item)}}},[_c('img',{attrs:{"src":item.pic,"alt":""}}),_c('div',[_vm._v(_vm._s(item.menuName))])])}),0):_c('div',{staticClass:"content"},[_c('van-form',{ref:"form",staticStyle:{"width":"80%"},on:{"submit":_vm.onSubmit}},[_c('h3',[_vm._v("姓名")]),_c('van-field',{attrs:{"rows":"3","autosize":"","name":"name","placeholder":"请输入姓名","show-word-limit":"","rules":[{ required: true, message: '请输入您的姓名' }]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('h3',[_vm._v("手机号")]),_c('van-field',{attrs:{"name":"phone","placeholder":"请输入手机号","show-word-limit":"","rules":[
            { required: true, message: '请填写您的手机号码！' },
            { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' }
          ]},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('h3',[_vm._v("邮箱")]),_c('van-field',{attrs:{"name":"email","placeholder":"请输入邮箱","show-word-limit":"","rules":[
            { required: true, message: '请填写您的邮箱！' },
            { pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/, message: '邮箱格式错误！' }
          ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('h3',[_vm._v("想要了解的解决方案")]),_c('van-field',{attrs:{"autocomplete":"off","rows":"3","autosize":"","name":"problemType","placeholder":"请输入想要了解的解决方案","show-word-limit":"","rules":[{ required: true, message: '请输入想要了解的解决方案' }]},on:{"click":function($event){_vm.show = true}},model:{value:(_vm.problemType),callback:function ($$v) {_vm.problemType=$$v},expression:"problemType"}}),(_vm.isOther)?_c('h3',[_vm._v("想要了解的其他解决方案")]):_vm._e(),(_vm.isOther)?_c('van-field',{attrs:{"autocomplete":"off","rows":"3","name":"otherProblemType","placeholder":"请输入想要了解的解决方案","show-word-limit":"","rules":[{ required: true, message: '请输入想要了解的其他解决方案' }]},model:{value:(_vm.otherProblemType),callback:function ($$v) {_vm.otherProblemType=$$v},expression:"otherProblemType"}}):_vm._e(),_c('h3',[_vm._v("想要了解的内容")]),_c('van-field',{attrs:{"rows":"3","autosize":"","type":"textarea","name":"problemDescribe","maxlength":"500","placeholder":"请输入想要了解的内容","show-word-limit":"","rules":[{ required: true, message: '请输入想要了解的内容' }]},model:{value:(_vm.problemDescribe),callback:function ($$v) {_vm.problemDescribe=$$v},expression:"problemDescribe"}}),_c('div',{staticStyle:{"margin":"60px 0","padding-bottom":"20px"}},[_c('van-button',{attrs:{"round":"","block":"","type":"info","disabled":_vm.disabled,"native-type":"submit"}},[_vm._v("提交")])],1)],1),_c('van-action-sheet',{attrs:{"actions":_vm.actions},on:{"select":_vm.onSelect},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }